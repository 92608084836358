import * as types from './mutation_types';

export default {
  [types.RESET](state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      items: [],
      message: ''
    });
  },
  
  [types.SET_LOADING](state, isLoading) {
    Object.assign(state, {
      isLoading
    });
  },

  [types.SET_ERROR](state, error) {
    Object.assign(state, {
      error
    });
  },

  [types.SET_ITEM_INFO](state, itemInfo) {
    Object.assign(state, {
      itemInfo
    });
  },

  [types.SET_ITEMS](state, items) {
    Object.assign(state, {
      items
    });
  },

  [types.SET_MESSAGE](state, message) {
    Object.assign(state, {
      message
    });
  },

  [types.CURRENT_PAGE](state, currentPage) {
    Object.assign(state, {
      currentPage
    });
  },

  [types.TOTAL_ROWS](state, totalRows) {
    Object.assign(state, {
      totalRows
    });
  },

  [types.PER_PAGE](state, perPage) {
    Object.assign(state, {
      perPage
    });
  },

};