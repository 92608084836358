import * as types from './mutation_types';

export default {
  [types.RESET](state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      items: [],
      links: [],
      meta: null,
      selectItems: null,
      totalItems: 0,
      listRefresh: false,
    });
  },

  [types.SET_ERROR](state, error) {
    Object.assign(state, { error });
  },

  [types.SET_ITEMS](state, items) {
    Object.assign(state, {
      error: '',
      items,
    });
  },

  [types.SET_LINKS](state, links) {
    Object.assign(state, { links });
  },

  [types.SET_META](state, meta) {
    Object.assign(state, { meta });
  },

  [types.SET_SELECT_ITEMS](state, selectItems) {
    Object.assign(state, { selectItems });
  },

  [types.TOGGLE_LOADING](state) {
    Object.assign(state, { isLoading: !state.isLoading });
  },

  [types.SET_LIST_REFRESH](state, listRefresh) {
    Object.assign(state, { listRefresh });
  },

  [types.SET_LOADING](state, isLoading) {
    Object.assign(state, { isLoading });
  },
};
