import { getField, updateField } from 'vuex-map-fields';
import * as actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    error: '',
    isLoading: false,
    items: [],
    search: null,
    itemInfo: null,
    isDevice: false,
    centerMap: {
      lat: 9.5704107,
      lng: 3.0290977
    },
    zoom: 7,
    searchDate: {
      startDate:null,
      endDate:null
    },
    isMap:true,
    isDraw:false,
    showButtonHistory:true,
    isTriangulate:false,
    message:"",
    selectItem: null,
    infosType:null,
  },
  actions,
  getters: {
    getField,
  },
  mutations: {
    updateField,
    ...mutations,
  }
};
