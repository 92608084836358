<template>
    <div class="wrapper">
        <nav id="sidebar" class="sidebar">
            <a class="sidebar-brand" href="/">
                GES Tracker - ALIBORA
            </a>
            <div class="sidebar-content">
                <div class="sidebar-user">
                    <img src="/img/avatars/avatar.jpg" class="img-fluid rounded-circle mb-2" alt="Linda Miller" />
                    <div class="fw-bold">{{ user?.name ?? "-" }}</div>
                    <small>{{ user?.email ?? "-" }}</small>
                </div>

                <ul class="sidebar-nav">
                    <li class="sidebar-header">
                        Mes Groupes
                    </li>
                    <Groupes />
                </ul>
            </div>
        </nav>
        <div class="main">
            <nav class="navbar navbar-expand navbar-theme">
                <a class="sidebar-toggle d-flex me-2" @click="toggleSidebar">
                    <i class="hamburger align-self-center"></i>
                </a>
                <div class="navbar-collapse collapse">
                    <ul class="navbar-nav ms-auto">

                        <li class="nav-item dropdown ms-lg-2">
                            <a class="nav-link dropdown-toggle position-relative" href="#" id="userDropdown"
                                data-bs-toggle="dropdown">
                                <i class="align-middle fas fa-cog"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-end" aria-labelledby="userDropdown">

                                <a class="dropdown-item" href="#" data-bs-toggle="modal"
                                    data-bs-target="#sizedModalLg"><i
                                        class="align-middle me-1 fas fa-fw fa-user"></i>Gestions des Utilisateurs</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" @click="logoutUser"><i
                                        class="align-middle me-1 fas fa-fw fa-arrow-alt-circle-right"></i>
                                    Déconnexion</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </nav>

            <!-- Start Modal Register -->
            <div class="modal fade" id="defaultModalPrimary" tabindex="-1" role="dialog" aria-hidden="true"
                :class="{ 'show': showModal }" @hidden="closeModal">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">Créer un utilisateur</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body m-3">
                            <div class="m-sm-4">
                                <div class="mb-3">
                                    <label for="name">Nom</label>
                                    <input class="form-control form-control-lg" type="text" name="name"
                                        v-model="userPost.name" placeholder="Nom" />
                                </div>
                                <div class="mb-3">
                                    <label for="user_name">Nom d'utilisateur</label>
                                    <input class="form-control form-control-lg" type="text" name="emai"
                                        v-model="userPost.email" placeholder="Nom d'utilisateur" />
                                </div>

                                <div class="mb-3">
                                    <label for="groupe">Groupes</label>
                                    <select multiple class="form-control form-control-lg" v-model="userPost.groups">
                                        <option :value="group.id" v-for="(group, index) in groupes" :key="index">{{
                        group.name }}</option>

                                    </select>
                                </div>


                                <div class="mb-3">
                                    <label for="message">Veuillez sélectionner votre fuseau horaire</label>
                                    <select class="form-control form-control-lg" v-model="userPost['timezone']">
                                        <option value="-12">GMT-12:00</option>
                                        <option value="-11">GMT-11:00</option>
                                        <option value="-10">GMT-10:00</option>
                                        <option value="-9.5">GMT-09:30</option>
                                        <option value="-9">GMT-09:00</option>
                                        <option value="-8">GMT-08:00</option>
                                        <option value="-7">GMT-07:00</option>
                                        <option value="-6">GMT-06:00</option>
                                        <option value="-5">GMT-05:00</option>
                                        <option value="-4.5">GMT-04:30</option>
                                        <option value="-4">GMT-04:00</option>
                                        <option value="-3.5">GMT-03:30</option>
                                        <option value="-3">GMT-03:00</option>
                                        <option value="-2">GMT-02:00</option>
                                        <option value="-1">GMT-01:00</option>
                                        <option value="0">GMT±00:00 (Greenwich Mean Time)</option>
                                        <option value="1">GMT+01:00</option>
                                        <option value="2">GMT+02:00</option>
                                        <option value="3">GMT+03:00</option>
                                        <option value="3.5">GMT+03:30</option>
                                        <option value="4">GMT+04:00</option>
                                        <option value="4.5">GMT+04:30</option>
                                        <option value="5">GMT+05:00</option>
                                        <option value="5.5">GMT+05:30</option>
                                        <option value="5.75">GMT+05:45</option>
                                        <option value="6">GMT+06:00</option>
                                        <option value="6.5">GMT+06:30</option>
                                        <option value="7">GMT+07:00</option>
                                        <option value="8">GMT+08:00</option>
                                        <option value="8.75">GMT+08:45</option>
                                        <option value="9">GMT+09:00</option>
                                        <option value="9.5">GMT+09:30</option>
                                        <option value="10">GMT+10:00</option>
                                        <option value="10.5">GMT+10:30</option>
                                        <option value="11">GMT+11:00</option>
                                        <option value="12">GMT+12:00</option>
                                        <option value="12.75">GMT+12:45</option>
                                        <option value="13">GMT+13:00</option>
                                        <option value="14">GMT+14:00</option>
                                    </select>

                                </div>


                                <div class="mb-3">
                                    <label for="password">Mot de passe</label>
                                    <input class="form-control form-control-lg" type="password" name="password"
                                        v-model="userPost.password" placeholder="Mot de passe" />
                                </div>
                                <div class="mb-3">
                                    <label for="confirm_password">confimer le Mot de passe</label>
                                    <input class="form-control form-control-lg" type="password"
                                        name="password_confirmation" v-model="userPost.password_confirmation"
                                        placeholder="confimer le Mot de passe" />
                                </div>
                            </div>
                            <!-- </div> -->
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-toggle="modal"
                                data-bs-target="#sizedModalLg">Annuler</button>
                            <button type="button" class="btn btn-primary"
                                @click="saveUser(userPost)">Enregistrer</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Modal Register -->

            <!-- Start Liste User Modal -->
            <div class="modal fade" id="sizedModalLg" tabindex="-1" role="dialog" aria-hidden="true"
                :class="{ 'show': showModal }" @hidden="closeModal">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="mx-3 my-2" style="text-align: end;">
                            <button type="button" class="btn-close " data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>

                        <div class="modal-header">
                            <h5 class="modal-title">Liste des Utilisateurs</h5>
                            <div class="d-flex" style="align-items: center;justify-content: center;">
                                <button type="button" class="btn btn-primary mx-2" data-bs-toggle="modal"
                                    data-bs-target="#defaultModalPrimary">Créer un utilisateur</button>
                            </div>
                        </div>
                        <div class="modal-body m-3" style="overflow-y: scroll; height: 360px" v-if="!isLoadingUserList">
                            <div class="col-md-12">
                                <div class="card">

                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th style="width:15%;">Date</th>
                                                <th style="width:18%;">Nom</th>
                                                <th style="width:20%">Nom d'utilisateur</th>
                                                <th class="d-none d-md-table-cell" style="width:10%">Groupes</th>
                                                <th class="text-center" style="width:22%">Statuts</th>
                                                <th style="width:60%">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            <!-- {{JSON.stringify(users)}} -->
                                            <tr v-for="(user, i) in users" :key="i">
                                                <td>{{ formatDate(user.created_at) }}</td>
                                                <td>{{ user["name"] }}</td>
                                                <td>{{ user["email"] }}</td>
                                                <td><b v-for="(group, i) in user['groupsname']" :key="i">{{ group + (i
                        == user['groupsname'].length - 1 ? "" : ", ") }} </b> </td>
                                                <td v-if="user['is_active']" class="text-success text-center"><i
                                                        class="fas fa-check"></i></td>
                                                <td v-else class="text-danger text-center"><i class="fas fa-remove"></i>
                                                </td>
                                                <td class="table-action">
                                                    <a @click="setShowItem(user)" data-bs-toggle="modal"
                                                        data-bs-target="#showusers"><i
                                                            class="align-middle fas fa-fw fa-eye"></i></a>&nbsp;
                                                    <a @click="setShowItem(user)" data-bs-toggle="modal"
                                                        data-bs-target="#editusers"><i
                                                            class="align-middle fas fa-fw fa-pen"></i></a>&nbsp;
                                                    <a @click="setUserDeleted(user)" data-bs-toggle="modal"
                                                        data-bs-target="#confirmDelet"><i
                                                            class="align-middle fas fa-fw fa-trash"></i></a>
                                                </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-body m-3" style="overflow-y: auto; height: 300px" v-else>
                            <div
                                style="display: flex; flex-direction: column;justify-content: center;align-items: center;height: 80%;">
                                <span class="loader"> </span>
                                <b>Chargement en cours</b>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-dark" data-bs-dismiss="modal">Fermer</button>
                            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
                        </div>
                    </div>
                </div>
            </div>
            <!-- End Liste User Modal -->

            <!-- ..................................Start Edit User Modal................................. -->
            <div class="modal fade" id="editusers" tabindex="-1" role="dialog" aria-hidden="true"
                :class="{ 'show': showModal }" @hidden="closeModal">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                                data-bs-target="#sizedModalLg"><i class="fas fa-angle-left"></i></button>&nbsp;
                            <h5 class="modal-title">Modifier les informations d'un utilisateur</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <edit-users></edit-users>
                    </div>
                </div>
            </div>
            <!-- ..................................End Edit User Modal.............................. -->


            <!-- ...............................Start Show User Modal..................................... -->

            <div class="modal fade" id="showusers" tabindex="-1" role="dialog" aria-hidden="true"
                :class="{ 'show': showModal }" @hidden="closeModal">
                <div class="modal-dialog modal-lg" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <button type="button" class="btn btn-primary mx-2" data-bs-toggle="modal"
                                data-bs-target="#sizedModalLg"><i class="fas fa-angle-left"></i></button>&nbsp;
                            <h5 class="modal-title">Voir les informations d'un utilisateur</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <show-users></show-users>
                    </div>
                </div>
            </div>
            <!-- .................................End Show User ............................... -->

            <!-- .................................Start Confrim Delete Button ............................... -->

            <div class="modal fade" id="confirmDelet" tabindex="-1" role="dialog" aria-hidden="true">
                <div class="modal-dialog modal-sm" role="document">
                    <div class="modal-content" v-if="userDeleted">
                        <div class="modal-body m-3">
                            <p class="mb-0">Voullez-vous réellement supprimer l'utilisateur (<b> {{ userDeleted['name']
                                    }} </b>) ?</p>
                        </div>
                        <div class="modal-footer">
                            <span data-bs-toggle="modal" data-bs-target="#sizedModalLg" type="button"
                                class="btn btn-secondary" data-bs-dismiss="modal">Non</span>
                            <!-- <button type="button" class="btn btn-primary"  >Oui</button> -->
                            <button type="button" class="btn btn-danger" @click="deleteUserItem" data-bs-toggle="modal"
                                data-bs-target="#sizedModalLg">Oui</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- .................................End Confrim Delete Button ............................... -->


            <main class="content">
                <div class="container-fluid">

                    <header-view />

                    <!-- <template v-if="!isLoading"> -->
                    <!-- <content-view /> -->
                    <content-test-view />
                    <!-- </template> -->
                    <MessageContent />

                </div>
            </main>

            <footer class="footer">
                <div class="container-fluid">
                    <div class="row text-muted">
                        <div class="col-8 text-start">
                            <ul class="list-inline">
                                <li class="list-inline-item">
                                    <a class="text-muted" href="#"></a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="text-muted" href="#"></a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="text-muted" href="#"></a>
                                </li>
                                <li class="list-inline-item">
                                    <a class="text-muted" href="#"></a>
                                </li>
                            </ul>
                        </div>
                        <div class="col-4 text-end">
                            <p class="mb-0">
                                &copy; 2024 - <a href="#" class="text-muted">ALIBORA</a>
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    </div>

    <svg width="0" height="0" style="position:absolute">
        <defs>
            <symbol viewBox="0 0 512 512" id="ion-ios-pulse-strong">
                <path
                    d="M448 273.001c-21.27 0-39.296 13.999-45.596 32.999h-38.857l-28.361-85.417a15.999 15.999 0 0 0-15.183-10.956c-.112 0-.224 0-.335.004a15.997 15.997 0 0 0-15.049 11.588l-44.484 155.262-52.353-314.108C206.535 54.893 200.333 48 192 48s-13.693 5.776-15.525 13.135L115.496 306H16v31.999h112c7.348 0 13.75-5.003 15.525-12.134l45.368-182.177 51.324 307.94c1.229 7.377 7.397 11.92 14.864 12.344.308.018.614.028.919.028 7.097 0 13.406-3.701 15.381-10.594l49.744-173.617 15.689 47.252A16.001 16.001 0 0 0 352 337.999h51.108C409.973 355.999 427.477 369 448 369c26.511 0 48-22.492 48-49 0-26.509-21.489-46.999-48-46.999z">
                </path>
            </symbol>
        </defs>
    </svg>
</template>


<script>

// import HelloWorld from '../../components/HelloWorld.vue'
// import MultiSelect from 'primevue/multiselect';
// import Dropdown from 'primevue/dropdown';
import Groupes from "../../components/devices/Groupes.vue"
import HeaderView from "../../components/HeaderView.vue"
// import ContentView from "../../components/ContentView.vue"
import ContentTestView from "../../components/ContentTestView.vue"
import { mapFields } from 'vuex-map-fields'
import MessageContent from "@/components/MessageContent.vue"
import { mapActions } from 'vuex'
import axios from 'axios';
import fetch from '@/utils/fetch';
import $ from 'jquery';
import { useToast } from 'vue-toastification';
import ShowUsers from '@/components/usersTable/ShowUsers.vue'
import EditUsers from '@/components/usersTable/EditUsers.vue'
import moment from 'moment';

import { SET_LIST_REFRESH, RESET } from "@/store/modules/users/list/mutation_types";

import store from '@/store';

// const url = process.env.VUE_APP_API_TRACKER_BASE_URL


export default {
    name: "HomePage",
    components: {
        Groupes,
        HeaderView,
        // MultiSelect,
        // Dropdown,
        ContentTestView,
        MessageContent,
        ShowUsers,
        EditUsers
    },
    data() {
        return {

            showModal: false,
            user: {
                email: '_',
                name: "_",
                groups: [],
                password: "_",
                password_confirmation: "_",
            },
            userDeleted: null,
            userPost: {
                email: '',
                name: "",
                groups: [],
                password: "",
                password_confirmation: "",
                timezone: "0"
            },
            // alert:""
            isModalOpen: true
        }
    },
    mounted() {
        this.user = JSON.parse(localStorage.getItem("user"))
        this.getAllGroups();
        this.getItems();
    },

    unmounted() {
        store.commit(`users/list/${RESET}`)
    },
    watch: {
        listRefresh() {
            if (this.listRefresh) {
                this.getItems();
            }
        }
        // commit(types.USER_DELETE_SET_DELETE_REFRESH, false);

    },
    computed: {
        ...mapFields('devices/list', {
            deviceItem: 'items',
            isLoading: 'isLoading',
            groupes: "groupes",

        }),
        ...mapFields('users/list', {
            users: "items",
            listRefresh: "listRefresh",
            isLoadingUserList: 'isLoading',


        }),

    },

    methods: {
        setUserDeleted(userDel) {
            this.userDeleted = userDel
        },
        ...mapActions({
            logout: 'auth/logout',
            getItems: "users/list/default",
            deleteUser: "users/del/deleteUser",
            setShowItem: "users/show/showItem",
            getAllGroups: "devices/list/getAllGroups",

        }),

        formatDate(date) {
            return moment(date).format('YYYY-MM-DD');
        },

        saveUser() {
            store.commit(`users/list/${SET_LIST_REFRESH}`, false)
            fetch({ method: 'POST', url: 'users', data: this.userPost })
                .then(({ data }) => {
                    this.userPost.email = "";
                    this.userPost.name = "";
                    this.userPost.timezone = "";
                    this.userPost.groups = [];
                    this.userPost.password = "";
                    this.userPost.password_confirmation = "";
                    const toast = useToast();
                    toast.success('Utilisateur ajouté avec succès !'); // Afficher le toast
                    store.commit(`users/list/${SET_LIST_REFRESH}`, true)

                    return data;
                })
                .catch((e) => {
                    // Gérer les erreurs ici
                    store.commit(`users/list/${SET_LIST_REFRESH}`, false)

                });
        },

        logoutUser() {
            this.logout().then(() => {
                this.$router.replace({ name: 'Login' })
            }).catch(() => {
                this.$router.replace({ name: 'Login' })
            })
        },
        toggleSidebar() {
            const sidebar = document.getElementById('sidebar');
            const isOpen = sidebar.classList.contains('toggled');
            if (isOpen) {
                sidebar.classList.remove('toggled');
            } else {
                sidebar.classList.toggle('toggled')
            }
        },

        deleteUserItem() {
            if (this.userDeleted) {
                this.deleteUser(this.userDeleted);

            } else {
                const toast = useToast();

                toast.open({
                    message: "Suppression échouée",
                    type: warning,
                })
            }
        }
    }

}
</script>

<style></style>