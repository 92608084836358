import fetch from '@/utils/fetch';
import * as types from './mutation_types';

import { SET_LIST_REFRESH } from "@/store/modules/users/list/mutation_types";
import { useToast } from 'vue-toastification';

import store from '@/store';

export const reset = ({ commit }) => {
  commit(types.RESET);
};

export const retrieve = ({ commit }, id, page="users") => {
  commit(types.TOGGLE_LOADING);

  id = page + '/' + id;
  return fetch(id)
    .then(response => response.json())
    .then((data) => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_RETRIEVED, data['data']);
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
    });
};

// export const update = ({ commit, state }, payload, page="users") => {
//   commit(types.SET_ERROR, '');
//   commit(types.TOGGLE_LOADING);

//   var id = page + '/' + state.retrieved['id'];
//   return fetch(id, {
//     method: 'PUT',
//     headers: new Headers({ 'Content-Type': 'application/ld+json' }),
//     body: JSON.stringify(payload),
//   })
//     .then(response => response.json())
//     .then((data) => {
//       commit(types.TOGGLE_LOADING);
//       commit(types.SET_UPDATED, data['data']);
//     })
//     .catch((e) => {
//       commit(types.TOGGLE_LOADING);

      

//       // eslint-disable-next-line
//       commit(types.SET_ERROR, e.message);

//       throw e;
//     });
// };

export const update = ({
  commit
}, data, page = 'users',) => {
  
  store.commit(`users/list/${SET_LIST_REFRESH}`, false)
  var id = page + '/' + data['id'];
  fetch({ method: 'PUT', url: id, data })

    .then(({
      data
    }) => {
      store.commit(`users/list/${SET_LIST_REFRESH}`, true)
      const toast = useToast();
      toast.success('Utilisateur modifié avec succès !'); // Afficher le toast

    })
    .catch((e) => {

      // commit(types.USER_DELETE_TOGGLE_LOADING);
      // commit(types.USER_DELETE_SET_ERROR, e.message);
      store.commit(`users/list/${SET_LIST_REFRESH}`, false)

    });
};


export const updateRetrieved = ({ commit }, updated) => {
  commit(types.UPDATE_RETRIEVED, updated);
};
