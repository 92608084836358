import fetch from '@/utils/fetch';
import * as types from './mutation_types';
import store from '@/store';

const getItems = ({
  commit
},page = 'users', ) => {
  commit(types.SET_LOADING, true);
  fetch({url:page})

    .then(({
      data
    }) =>  {
      commit(types.SET_ITEMS, data['data']);
      commit(types.SET_LIST_REFRESH, false);
      commit(types.SET_LOADING, false);

      return data;
    })
    .catch((e) => {
      commit(types.SET_ERROR, e.message);
      commit(types.SET_LOADING, true);

    });
};


export const getAllItems = (
  { commit },
  { page = 'users', params = { properties: ['@id', 'name'] } } = {},
) => {
  commit(types.TOGGLE_LOADING);
  commit(types.SET_LOADING, false);

  fetch(page, { params })
    .then(response => response.json())
    .then(data => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ITEMS, data['data']);
      commit(types.SET_LOADING, true);

    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
      commit(types.SET_LOADING, false);

    });
};
export const getAllItemsFilter = (
  { commit },
  params=[],
  page="users"
) => {
  commit(types.TOGGLE_LOADING);
  commit(types.SET_LOADING, false);

 let param_url ="";
 let separator ="&"
 params.forEach(p => {
  //  if(index > 0 ){
  //   separator = "?"
  //  }
   param_url += separator+p['key']+'['+p['op']+']='+p['value']
 });
 page +=param_url;
  fetch(page)
    .then(response => response.json())
    .then(data => {
      commit(types.TOGGLE_LOADING);
      
      commit(types.SET_ITEMS, data['data']);
      commit(types.SET_LINKS, data['links']);
      commit(types.SET_META, data['meta']);
      commit(types.SET_LOADING, false);

    })
    .catch(e => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
    });
};

export default getItems;
