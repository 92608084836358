import fetch from '@/utils/fetch';
import * as types from './mutation_types';

export const create = ({ commit }, values) => {
  commit(types.AGENT_CREATE_SET_ERROR, '');
  commit(types.AGENT_CREATE_TOGGLE_LOADING);

  return fetch('agents', { method: 'POST', body: JSON.stringify(values) })
    .then((response) => {
      commit(types.AGENT_CREATE_TOGGLE_LOADING);

      return response.json();
    })
    .then((data) => {
      commit(types.AGENT_CREATE_SET_CREATED, data['data']);
    })
    .catch((e) => {
      commit(types.AGENT_CREATE_TOGGLE_LOADING);

      

      commit(types.AGENT_CREATE_SET_ERROR, e.message);
      throw e;
    });
};

export const reset = ({ commit }) => {
  commit(types.AGENT_CREATE_RESET);
};
