<template>
    <div class="modal-body m-3" v-if="user">
        <div class="m-sm-4">
            <div class="mb-3">
                <label for="name">Nom</label>
                <input class="form-control form-control-lg" type="text" :value="user['name']" disabled />
            </div>
            <div class="mb-3">
                <label for="user_name">Nom d'utilisateur</label>
                <input class="form-control form-control-lg" type="text" :value="user['email']" disabled />
            </div>

            <div class="mb-3">
                <label for="groupe">Groupes: <b v-for="(group, i) in user['groupsname']" :key="i">{{ group +
        (i == user['groupsname'].length - 1 ? "" : ", ") }} </b></label>

            </div>
            <div class="form-check form-switch mb-3">
                <input :checked="user['is_active']" class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                    disabled>
                <label class="form-check-label" for="flexSwitchCheckChecked">Statut du compte</label>
            </div>
        </div>
        <!-- </div> -->
    </div>

    <!-- <div class="modal-backdrop fade show"></div> -->
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'


export default {
    name: "ShowUsers",
    data() {
        return {
            showModal: false,
            isModalOpen: true
        }
    },
    mounted() { },
    computed: {

        ...mapFields('users/show', {
            user: "item",
        }),
    },
    methods: {
    }

}
</script>

<style></style>