<template>
  <!-- {{ (isLoading) }} -->


  <div v-if="!isLoading">
    <li class="sidebar-item" @click="getDataForMap('null', search.SEARCH_ALL_LAST, $event,'null')">
      <a class="sidebar-link collapsed">
        <i class="align-middle me-2 fas fa-fw fa-users"></i> <span class="align-middle">Tous les groupes</span>
      </a>
    </li>

    <li class="sidebar-item" v-for="(item, index) in result" :key="index"
      @click="getDataForMap(item, search.SEARCH_GROUP_LAST, $event,item['id'] ?? 'null')">

      <a :data-bs-target="'#group' + index" data-bs-toggle="collapse" class="sidebar-link collapsed" v-if="item">
        <i class="align-middle me-2 fas fa-fw fa-users"></i> <span class="align-middle">{{ item["name"] ?? 'Sans groupe'
        }}</span>
      </a>

      <ul :id="'group' + index" class="sidebar-dropdown list-unstyled collapse"
        v-for="(deviceItem, i) in (item  ? item['devices']: []) " :key="i" data-bs-parent="#sidebar" >
        <Device :deviceItem="deviceItem" @click="getDataForMap(deviceItem, search.SEARCH_DEVICE_LAST, $event,deviceItem['imei'])" />
      </ul>
    </li>

  </div>
</template>
  
<script>
import store from '@/store';
import Device from './Device.vue';
import { mapFields } from "vuex-map-fields"
import { mapActions } from 'vuex';
import * as search from '@/store/modules/map/list/mutation_types';

// import device from '@/store/modules/device';

export default {
  name: "GroupesItem",
  data() {
    return {
      result: [],

    }
  },

  computed: {
    ...mapFields('devices/list', {

      items: 'items',

      isLoading: 'isLoading',
    }),
    search() {
      return search;
    }


  },
  mounted() {
    this.getDevices()
    this.getAllMessages()
  },
  watch: {
    "items.length"() {
      if (this.items.length > 0) {
        this.devicesFilter()
      }
    }
  },
  methods: {
    ...mapActions({
      getDevices: "devices/list/default",
      getMapData: "map/list/getMapData",
      getMessages: "messages/list/getMessages",
      getAllMessages: "messages/list/getAllMessages",
      resetMapData: "map/list/resetMapData",
      resetDevices: "devices/list/resetDevices",
    }),

    devicesFilter() {
      let result = [];
      let devices = this.items

      devices.forEach(device => {
        if (!result[device.group_id]) {
          result[device.group_id ?? 0] = {
            ...device.group,
            devices: [],
          }
        }
        result[device.group_id ?? 0]['devices'].push(device)
      });
      this.result = result;
    },

    getDataForMap(item, type, e,itemMessage = 'null') {
      e.stopPropagation()
      e.preventDefault()

      // changement de la valeur de la variable pour afficherle bouton Historique
      store.commit(`map/list/${search.SET_SHOW_BUTTON_HISTORY}`, true);
      store.commit(`map/list/${search.SET_IS_DRAW}`, false);
      store.commit(`map/list/${search.SET_IS_MAP}`, true);
      store.commit(`map/list/${search.SET_SEARCH_DATE}`,{startDate:null,endDate:null})

      let data = item['id'] ?? 'null';
      this.getMapData({ type, data })
      this.getMessages({ type, data:itemMessage })


    }
  },
  unmounted() {
    this.resetMapData();
    this.resetDevices()
  },

  components: { Device }
}
</script>
  
  