import * as types from "./mutation_types";

export default {
  [types.RESET](state) {
    Object.assign(state, {
      error: "",
      isLoading: false,
      items: [],
      search: null,
      isDevice: null,
      message: "",
      selectItem: null,
      centerMap: {
        lat: 9.5704107,
        lng: 3.0290977,
      },
      zoom: 7,
      searchDate: {
        startDate: null,
        endDate: null,
      },
      showButtonHistory: true,
      isMap: false,
      isDraw: false,
      isTriangulate: false,
      infosType:null,

    });
  },

  [types.SET_ERROR](state, error) {
    Object.assign(state, {
      error,
    });
  },

  [types.SET_SHOW_BUTTON_HISTORY](state, showButtonHistory) {
    Object.assign(state, {
      showButtonHistory,
    });
  },
  [types.SET_SEARCH_DATE](state, date) {
    Object.assign(state, {
      searchDate: date,
    });
  },
  [types.SET_INFOS_TYPE](state, infosType) {
    Object.assign(state, {
      infosType,
    });
  },

  [types.SET_LOADING](state, isLoading) {
    Object.assign(state, {
      isLoading,
    });
  },
  [types.SET_CENTER_MAP](state, centerMap) {
    Object.assign(state, {
      centerMap,
    });
  },
  [types.SET_ZOOM_MAP](state, zoom) {
    Object.assign(state, {
      zoom,
    });
  },
  [types.SET_ITEMS](state, items) {
    Object.assign(state, {
      error: "",
      items,
    });
  },
  [types.SET_IS_TRIANGLE](state) {
    Object.assign(state, {
      isTriangulate: true,
      isDraw: false,
      isMap: false,
    });
  },
  [types.SET_IS_MAP](state) {
    Object.assign(state, {
      isTriangulate: false,
      isDraw: false,
      isMap: true,
    });
  },
  [types.SET_IS_DRAW](state) {
    Object.assign(state, {
      isTriangulate: false,
      isDraw: !state.isDraw,
      isMap: false,
    });
  },
  [types.SET_IS_DEVICE](state, isDevice) {
    Object.assign(state, {
      isDevice,
    });
  },

  [types.SET_ITEM_INFO](state, itemInfo) {
    Object.assign(state, {
      itemInfo,
    });
  },
  [types.SET_MESSAGE](state, message) {
    Object.assign(state, {
      message,
    });
  },

  [types.SET_SEARCH](state, search) {
    Object.assign(state, {
      search,
    });
  },

  [types.SET_SELECT_ITEM](state, selectItem) {
    Object.assign(state, {
      selectItem,
    });
  },

  [types.TOGGLE_LOADING](state) {
    Object.assign(state, {
      isLoading: !state.isLoading,
    });
  },
};
