<template>
    <router-view></router-view>
</template>

<script>

import "@/assets/js/app";
import { mapActions } from 'vuex';
export default {
  name: 'App',
  mounted() {
    this.checkAuth().then(() => {
      this.$router.replace({ name: 'Home' })
    }).catch((err) => {
      this.$router.replace({ name: 'Login' })

    })
  },
  methods: {
    ...mapActions({
      checkAuth: 'auth/checkToken'
    })
  }

}
</script>


<style></style>
