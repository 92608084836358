import * as types from './mutation_types';

export default {
  [types.USER_SHOW_RESET](state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      retrieved: null,
      item:null,
      modalShower:{
        updateItem:false,
        deleteItem:false,
        showItem:false,
      }
    });
  },

  [types.USER_SHOW_SET_ERROR](state, error) {
    Object.assign(state, { error });
  },

  [types.USER_SHOW_SET_MODAL_SHOWER](state, modalShower) {
    Object.assign(state, { modalShower });
  },

  [types.USER_SHOW_SET_ITEM](state, item) {
    Object.assign(state, { item });
  },


  [types.USER_SHOW_SET_RETRIEVED](state, retrieved) {
    Object.assign(state, { retrieved: retrieved, isLoading: false });
  },

  [types.USER_SHOW_TOGGLE_LOADING](state) {
    Object.assign(state, { isLoading: !state.isLoading });
  },
};
