export const RESET = 'DEVICES_LIST_RESET';
export const SET_ITEMS = 'DEVICES_LIST_SET_ITEMS';
export const SET_SEARCH = 'DEVICES_LIST_SET_SEARCH';
export const SET_IS_DEVICE = 'DEVICES_LIST_SET_IS_DEVICE';
export const SET_ITEM_INFO = 'DEVICES_LIST_SET_ITEM_INFO';
export const SET_ERROR = 'DEVICES_LIST_SET_ERROR';
export const SET_MESSAGE = 'DEVICES_LIST_SET_MESSAGE';
export const TOGGLE_LOADING = 'DEVICES_LIST_TOGGLE_LOADING';
export const SET_LOADING = 'DEVICES_LIST_SET_LOADING';
export const SET_SELECT_ITEM = 'DEVICES_LIST_SET_SELECT_ITEM';
export const SET_GROUPES_ITEMS = 'GROUPES_LIST_SET_GROUPES_ITEMS';
    

