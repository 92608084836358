<template>
    <div class="row">
        <div :class="messageCard ? 'col-12 col-lg-8 col-xxl-8 d-flex' : 'col-12 col-lg-12 col-xxl-12 d-flex'">
            <div class="card flex-fill">
                <div class="card-header d-flex justify-content-between align-items-center my-3">
                    <!-- <h5 class="card-title mb-0">Messages</h5> -->
                    <!-- <button class="btn btn-primary" @click="setShowMessageCard(true)" v-if="!messageCard">
                        Envoyer un message
                    </button> -->

                </div>
                <div class="card-body tableFixHead" style="background-color: white;margin: 0px;padding: 0px 10px;">
                    <table id="datatables-dashboard-projects" class="table table-striped"
                        style="background-color: white;" v-if="!isLoading">
                        <thead style="background-color: white;">
                            <tr style="background-color: white;">
                                <th style="background-color: white;">Date</th>
                                <th class=" d-xl-table-cell" style="background-color: white;">De</th>
                                <th class=" d-xl-table-cell" style="background-color: white;">A</th>
                                <th class=" d-xl-table-cell" style="background-color: white;">Type</th>
                                <th class=" d-xl-table-cell" style="background-color: white;">Message</th>
                                <!-- <th class="d-none d-md-table-cell">Assignee</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(message, i) in messages" :key="i" style="">
                                <td class=" d-xl-table-cell">{{ message['sender_date'] }}</td>
                                <td class=" d-xl-table-cell">
                                    {{ message['sender_name'] }} <br />
                                    {{ message['sender_imei'] }}
                                </td>
                                <td class=" d-xl-table-cell">
                                    {{ message['receiver_imei'] }} <br />
                                    {{ message['receiver_name'] }}
                                </td>
                                <td class=" d-xl-table-cell">
                                    <MessageCode :code="message['status']" />
                                </td>
                                <td class=" d-xl-table-cell">{{ message['message'] }}</td>
                            </tr>
                        </tbody>

                    </table>
                    <div class="d-flex flex-column justify-content-center align-items-center" style="height: 80%;"
                        v-else>
                        <span class="loader"> </span>
                        <b>Chargement en cours</b>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-4 col-xxl-4 d-flex" v-if="messageCard">
            <div class="card flex-fill">
                <div class="card-header d-flex justify-content-between align-items-center">
                    <h5 class="card-title mb-0">Envoyer un message</h5>
                    <button type="button" class="btn-close" @click="setShowMessageCard(false)"></button>


                </div>
                <form action="" class="card-body">
                    <div class="mb-3" v-if="messageContent.receiver_imei.length === 0">
                        <label for="message" class="text-danger">Destinataire <span>*</span></label>
                        <div class="card flex justify-content-center">
                            <MultiSelect v-model="messageContent.receiver_imei" :options="devices" filter
                                optionLabel="name" placeholder="Choisissez" :maxSelectedLabels="5"
                                class="w-full md:w-20rem " />
                        </div>
                        <small class="text-danger">Veuillez sélectionner au moins un destinataire.</small>
                    </div>
                    <div class="mb-3" v-else>
                        <label for="message">Destinataire</label>
                        <div class="card flex justify-content-center">
                            <MultiSelect v-model="messageContent.receiver_imei" :options="devices" filter
                                optionLabel="name" placeholder="Choisissez" :maxSelectedLabels="5"
                                class="w-full md:w-20rem " />
                        </div>
                    </div>


                    <div class="mb-3" :class="{ 'has-error': messageContent.message.length === 0 && formSubmitted }">
                        <label for="message" class="text-danger">Message <span>*</span></label>
                        <textarea v-model="messageContent.message" id="message" cols="40" rows="3"
                            class="form-control form-control-lg"></textarea>
                        <small v-if="messageContent.message.length === 0 && formSubmitted" class="text-danger">Ce champ
                            est obligatoire.</small>
                    </div>
                    <button v-if="!isLoading" type="button" class="btn btn-primary"
                        @click="sendMessage(messageContent)">Envoyer</button>

                    <div v-else class="d-flex" style="align-items: center;">
                        <span class="loader ml-2 "></span>
                        <div style="width: max-content;" class="mx-2"> Envoi en cours ...</div>
                    </div>


                </form>
            </div>
        </div>
    </div>

</template>


<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions } from 'vuex';
import MultiSelect from 'primevue/multiselect';
import fetch from '@/utils/fetch';
import { useToast } from 'vue-toastification';
import * as types from '@/store/modules/messages/list/mutation_types';
import store from '@/store';

import MessageCode from './widgets/MessageCode.vue';


export default {
    name: "MessageContent",
    components: {
        MultiSelect,
        MessageCode,
    },
    data() {
        return {
            messageContent: {
                sender_imei: 'SYSTEME',
                sender_name: "SYSTEME",
                receiver_imei: [],
                message: '',
            },
            messageCard: false,
            devices: [],
            formSubmitted: false
        };
    },
    computed: {
        ...mapFields('messages/list', {
            messages: 'items',
            currentPage: 'currentPage',
            perPage: 'perPage',
            totalRows: "totalRows",
            isLoading: 'isLoading',

        }),
    },
    mounted() {
        $('#mySelect').select2();
        this.fetchData();
        this.getAllDeviceLite();
    },
    methods: {
        ...mapActions({
            // getMessages: "messages/list/default",
        }),
        setShowMessageCard(value) {
            this.messageCard = value
        },
        fetchData() {
            // this.getMessages();
        },
        getAllDeviceLite() {
            fetch({ method: 'GET', url: 'device-lite' })
                .then(({ data }) => {
                    this.devices = data['data'];
                })
                .catch((e) => {
                    // Gérer les erreurs ici
                });
        },
        sendMessage() {

            if (this.messageContent.message.length === 0) {
                this.formSubmitted = true;
                return; // Ne pas envoyer le message si le champ est vide
            }

            store.commit(`messages/list/${types.SET_LOADING}`, true);
            fetch({ method: 'POST', url: 'messages', data: this.messageContent })
                .then(({ data }) => {
                    this.fetchData();
                    const toast = useToast();
                    toast.success('Message envoyé avec succès !');
                    this.messageContent.message = ""
                    this.messageContent.receiver_imei = []
                    store.commit(`messages/list/${types.SET_LOADING}`, false);

                    return data;

                })
                .catch((e) => {
                    const toast = useToast();
                    toast.error(e["response"]["data"]["message"]);
                    store.commit(`messages/list/${types.SET_LOADING}`, false);

                });
        },
    },
}
</script>

<style>
.tableFixHead {
    overflow: auto;
    height: 400px;
}

.tableFixHead thead {
    position: sticky;
    top: 0;
    z-index: 1;
}
</style>