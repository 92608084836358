<template>
    <div class="px-1 d-flex justify-content-center align-items-center py-1 "
        :style="{ backgroundColor:( messagesCodeDatas[code]?.color ??  messagesCodeDatas['undefine'].color  ),borderRadius:'20px',fontSize:'10px',color:'white',fontWeight:600}">
        <div class="">
            {{ (messagesCodeDatas[code]?.label ?? messagesCodeDatas['undefine'].label ) }}
        </div>
    </div>
</template>

<script>
export default {
    name:"MessageCodeWidget",
    data() {
        return {
            messagesCodeDatas: {
                '0': {
                    label: "Position Report",
                    description: "Drops a breadcrumb while tracking.",
                    color: 'blue'
                },
                '1': {
                    label: "Reserved",
                    description: "Reserved for later use.",
                    color: 'blue'
                },
                '2': {
                    label: "Locate Response",
                    description: " Position for a locate request.",
                    color: 'blue'
                },
                '3': {
                    label: "Message",
                    description: "Message containing a free-text block.",
                    color: '#007bff',
                },
                '4': {
                    label: "SOS",
                    description: "Declares an emergency state.",
                    color: 'red'
                },
                '5': {
                    label: "SOS",
                    description: "Reserved for later use.",
                    color: 'red'
                },
                '6': {
                    label: "SOS",
                    description: "Confirms an unconfirmed SOS",
                    color: 'red'
                },
                '7': {
                    label: "SOS",
                    description: "Stops a SOS event.",
                    color: 'red'
                },

                'undefine': {
                    label: "UNKNOW",
                    description: " No description",
                    color: 'black'
                }
            }
        }
    },
    props: {
        code: {
            type:String,
            required: true,
        }
    }


}</script>