export const RESET = 'MESSAGES_LIST_RESET';
export const SET_ERROR = 'MESSAGES_LIST_SET_ERROR';
export const SET_MESSAGE = 'MESSAGES_LIST_SET_MESSAGE';
export const SET_ITEMS = 'MESSAGES_LIST_SET_ITEM';
export const SET_ITEM_INFO = 'MESSAGES_LIST_SET_ITEM_INFO';
export const TOGGLE_LOADING = 'MESSAGES_LIST_TOGGLE_LOADING';
export const SET_LOADING = 'MESSAGES_LIST_SET_LOADING';

// Select .* from ...  where id = ... 
export const SEARCH_DEVICE = 'MESSAGES_LIST_GET_SEARCH_DEVICE';

// Select .* from ...  where group_id = ... 
export const SEARCH_GROUP = 'MESSAGES_LIST_GET_SEARCH_GROUP';

export const SEARCH_ALL = 'MESSAGES_LIST_GET_SEARCH_ALL';


export const CURRENT_PAGE = 'MESSAGES_LIST_GET_CURRENT_PAGE';
export const PER_PAGE = 'MESSAGES_LIST_GET_PER_PAGE';
export const TOTAL_ROWS = 'MESSAGES_LIST_GET_TOTAL_ROWS';
