import fetch from '@/utils/fetch';
import * as types from './mutation_types';

export const retrieve = ({ commit }, id, page="users") => {
  commit(types.USER_SHOW_TOGGLE_LOADING);

  id = page + '/' + id;
  return fetch(id)
    .then(response => response.json())
    .then((data) => {
      commit(types.USER_SHOW_TOGGLE_LOADING);
      commit(types.USER_SHOW_SET_RETRIEVED, data['data']);
    })
    .catch((e) => {
      commit(types.USER_SHOW_TOGGLE_LOADING);
      commit(types.USER_SHOW_SET_ERROR, e.message);
      throw e;
    });
};

export const reset = ({ commit }) => {
  commit(types.USER_SHOW_RESET);
};


export const showItem = ({ commit },item) => {
  commit(types.USER_SHOW_SET_ITEM, item);
};
