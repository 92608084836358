<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div>
        <h2></h2>
    </div>
</template>

<script>

export default {
  
}
</script>

<style></style>