import fetch from '@/utils/fetch';
import * as types from './mutation_types';

import store from '@/store';
import 'moment'; 

const showInMap = (data,params) => {


  let events = fetchEvents(data)

  store.commit(`map/list/${types.SET_ITEMS}`, events)
  store.commit(`map/list/${types.SET_ITEMS}`, events)
  store.commit(`map/list/${types.SET_ITEM_INFO}`, params);

  // commit(types.SET_ITEMS, data['events'])
  store.commit(`map/list/${types.SET_LOADING}`, false);

};
const fetchEvents = (data) => {
  let events = []
  for (let i = 0; i < data.length; i++) {
    const evs = data[i]['eventsdata'];
    if (evs) {
      events.push(...evs)
    }

  }
  return events
}

export const centerPosition =({
  commit
}, {position, zoom,selectItem}) => {
  commit(types.SET_CENTER_MAP, position);
  commit(types.SET_ZOOM_MAP, zoom);
  commit(types.SET_SELECT_ITEM, selectItem);
}


const paramsFilter = (type, data) => {
  let result = '?';

  if (type == types.SEARCH_GROUPS) {
    result += ''
  }
  if (type == types.SEARCH_DEVICES) {
    result += ''
  }
  if (type == types.SEARCH_GROUP || type == types.SEARCH_GROUP_LAST) {
    result += `group_id[eq]=${data}&`
  }

  if (type == types.SEARCH_DEVICE_LAST || type == types.SEARCH_DEVICE) {
    result += `id[eq]=${data}&`

  }


  
  result += `lastevent=${type.includes('LAST')}`
  // let t = new URLSearchParams(['id','2'])


  return result;

}

export const getMapData = ({
  commit
}, { type, data, isParamsFilter = true }, page = 'devices') => {

  var params = isParamsFilter ? paramsFilter(type, data) : type


  if (type == types.SEARCH_DATE) {
    let  param = data.lastSearch?.split("&start_date")[0] == undefined ? "?":data.lastSearch.split("&start_date")[0]+"&"
    params = `${(param)}start_date=${moment(data.searchDate.startDate).format("YYYY-MM-DD")}&end_date=${moment(data.searchDate.endDate).format("YYYY-MM-DD")}`
  }
  commit(types.SET_LOADING, true);
  fetch({
    url: `${page}${params}`,

  })
    .then(({
      data
    }) => {

      let items = fetchEvents(data['data']);
      commit(types.SET_LOADING, false);
      commit(types.SET_ITEMS, items);
      commit(types.SET_IS_DEVICE, data['isdevice']);
      commit(types.SET_INFOS_TYPE, data['info']);
      commit(types.SET_ITEM_INFO, params);
      // commit(types.SET_SELECT_ITEM, null);
      commit(types.SET_SEARCH, "");

      commit(types.SET_MESSAGE, data['message']);
      // showInMap(data['data']);

      return data;
    })
    .catch((e) => {
      // commit(types.TOGGLE_LOADING);
      commit(types.SET_LOADING, false);

      commit(types.SET_ERROR, e.message);
    });
};

export const resetMapData = ({ commit }) => {
  commit(types.RESET)
}

export default showInMap;