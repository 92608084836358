<template>
    <div class="modal-body m-3" v-if="user">
        <div class="m-sm-4">
            <div class="mb-3">
                <label for="name">Nom</label>
                <input class="form-control form-control-lg" type="text" v-model="userEdit['name']" />
            </div>
            <div class="mb-3">
                <label for="user_name">Nom d'utilisateur</label>
                <input class="form-control form-control-lg" type="text" v-model="userEdit['email']" />
            </div>

            <div class="mb-3">
                <label for="groupe">Groupes</label>
                <select multiple class="form-control form-control-lg" v-model="userEdit['groups']">
                    <option value="null">Sans Groupe</option>
                    <option :value="group.id" v-for="(group, index) in groupes" :key="index">{{
        group.name }}</option>
                </select>
            </div>

            <div class="mb-3">
                <div class="mb-3">
                    <label for="message">Veuillez sélectionner votre fuseau horaire</label>
                    <select class="form-control form-control-lg" v-model="userEdit['timezone']">
                        <option value="-12">GMT-12:00</option>
                        <option value="-11">GMT-11:00</option>
                        <option value="-10">GMT-10:00</option>
                        <option value="-9.5">GMT-09:30</option>
                        <option value="-9">GMT-09:00</option>
                        <option value="-8">GMT-08:00</option>
                        <option value="-7">GMT-07:00</option>
                        <option value="-6">GMT-06:00</option>
                        <option value="-5">GMT-05:00</option>
                        <option value="-4.5">GMT-04:30</option>
                        <option value="-4">GMT-04:00</option>
                        <option value="-3.5">GMT-03:30</option>
                        <option value="-3">GMT-03:00</option>
                        <option value="-2">GMT-02:00</option>
                        <option value="-1">GMT-01:00</option>
                        <option value="0">GMT±00:00 (Greenwich Mean Time)</option>
                        <option value="1">GMT+01:00</option>
                        <option value="2">GMT+02:00</option>
                        <option value="3">GMT+03:00</option>
                        <option value="3.5">GMT+03:30</option>
                        <option value="4">GMT+04:00</option>
                        <option value="4.5">GMT+04:30</option>
                        <option value="5">GMT+05:00</option>
                        <option value="5.5">GMT+05:30</option>
                        <option value="5.75">GMT+05:45</option>
                        <option value="6">GMT+06:00</option>
                        <option value="6.5">GMT+06:30</option>
                        <option value="7">GMT+07:00</option>
                        <option value="8">GMT+08:00</option>
                        <option value="8.75">GMT+08:45</option>
                        <option value="9">GMT+09:00</option>
                        <option value="9.5">GMT+09:30</option>
                        <option value="10">GMT+10:00</option>
                        <option value="10.5">GMT+10:30</option>
                        <option value="11">GMT+11:00</option>
                        <option value="12">GMT+12:00</option>
                        <option value="12.75">GMT+12:45</option>
                        <option value="13">GMT+13:00</option>
                        <option value="14">GMT+14:00</option>
                    </select>

                </div>
            </div>

            <div class="form-check form-switch mb-3">

                <input v-if="userEdit['is_active'] == 1" v-model="userEdit['is_active']" class="form-check-input" type="checkbox" id="flexSwitchCheckChecked" checked="">
                <input v-else-if="userEdit['is_active'] == 0" v-model="userEdit['is_active']" class="form-check-input" type="checkbox" id="flexSwitchCheckChecked">

                <label class="form-check-label" for="flexSwitchCheckChecked">Statut du compte</label>
            </div>

            <div class="mb-3">
                <label for="password">Mot de passe</label>
                <input class="form-control form-control-lg" type="password" v-model="userEdit['password']" />
            </div>
            <div class="mb-3">
                <label for="confirm_password">confimer le Mot de passe</label>
                <input class="form-control form-control-lg" type="password"
                    v-model="userEdit['password_confirmation']" />
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Annuler</button>
        <button type="button" class="btn btn-primary" @click="editUser(userEdit)"
            data-bs-dismiss="modal">modifier</button>
    </div>
</template>

<script>

import { mapFields } from 'vuex-map-fields'
import { mapActions } from 'vuex'
export default {
    name: "EditUsers",
    data() {
        return {
            showModal: false,
            userEdit: {
                id: "",
                email: "",
                name: "",
                is_active: true,
                groups: [],
                timezone: "0"
            },
            isModalOpen: true
        }
    },

    mounted() {
        this.getAllGroups();
    },
    computed: {
        ...mapFields('users/show', {
            user: "item",
        }),
        ...mapFields('devices/list', {
            groupes: "groupes",
        }),
    },

    watch: {
        user() {
            if (this.user) {
                this.user.is_active == 1 ? this.user.is_active = true : this.user.is_active = false;
                Object.assign(this.userEdit, { ...this.user })
                console.log(this.userEdit);
            }
        }
    },
    methods: {

        ...mapActions({
            updateItem: "users/update/update",
            getAllGroups: "devices/list/getAllGroups",
        }),

        //  editUser() {
        //     fetch({ method: 'PUT', url: 'users', data: this.userEdit })
        //     .then(({ data }) => {
        //             this.userEdit.email = "";
        //             this.userEdit.name = "";git pull
        //             this.userEdit.groups = [];
        //             this.userEdit.password = "";
        //             this.userEdit.password_confirmation = "";
        //             const toast = useToast();
        //             toast.success('Utilisateur modifié avec succès');
        //             return data;
        //         })
        //         .catch((e) => {
        //         });
        // },

        editUser() {
            let data = this.userEdit;
            // console.log(data);
            this.updateItem(data);
        },
    }

}
</script>

<style></style>