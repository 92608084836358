import axios from "axios";
import fetch, { API_URL } from "@/utils/fetch";

export default {
  namespaced: true,

  state: {
    authuser: {
      user: {},
      // user: null,
      token: null,
    },
  },

  getters: {
    authenticated(state) {
      return state.authuser && state.authuser.token && state.authuser.user;
    },

    user(state) {
      return state.authuser.user;
    },
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.authuser.token = token;
    },

    SET_USER(state, user) {
      state.authuser.user = user;
    },
  },

  actions: {
    //  async register(user){
    //     return axios.post(`${API_URL}`,{
    //         name: user.name,
    //         username: user.username,
    //         password: user.password
    //     })
    //  },

    async login({ dispatch }, credentials) {
      return new Promise(async (resolve, reject) => {
        try {
          let response = await axios.post(`${API_URL}login`, credentials);
          dispatch("attempt", response.data);
          resolve("is Auth");
        } catch (error) {
          reject(error);
        }
      });
    },

    async attempt({ commit, state }, response) {
      if (response) {
        commit("SET_TOKEN", response.token);
        commit("SET_USER", response.data);
        localStorage.setItem("token", response.token);
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      if (!state.authuser.token) {
        return;
      }
    },

    async logout({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          let response = await axios.post(`${API_URL}logout`);
          commit("SET_TOKEN", null);
          commit("SET_USER", null);
          localStorage.clear();

          resolve(true);
        } catch (error) {
          localStorage.clear();

          commit("SET_TOKEN", null);
          commit("SET_USER", null);
          reject(error);
        }
      });
    },
    async checkToken({ commit }) {
      var tokenStr = localStorage.getItem("token") ?? "no-token";
      return new Promise(async (resolve, reject) => {
        try {
          let response = await axios.get(`${API_URL}user`, {
            headers: { Authorization: `Bearer ${tokenStr}` },
          });
          let result = response.data
          commit("SET_TOKEN", tokenStr);
          commit("SET_USER", result.data);
          localStorage.setItem("user", JSON.stringify(result.data));
          resolve("is Auth");
        } catch (error) {
          commit("SET_TOKEN", null);
          commit("SET_USER", null);
          reject(error);
        }
      });
    },
  },
};
