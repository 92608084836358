export const RESET = 'MAPS_LIST_RESET';
export const SET_ITEMS = 'MAPS_LIST_SET_ITEMS';
export const SET_SEARCH = 'MAPS_LIST_SET_SEARCH';
export const SET_IS_DEVICE = 'MAPS_LIST_SET_IS_DEVICE';
export const SET_ITEM_INFO = 'MAPS_LIST_SET_ITEM_INFO';
export const SET_ERROR = 'MAPS_LIST_SET_ERROR';
export const SET_MESSAGE = 'MAPS_LIST_SET_MESSAGE';
export const SET_INFOS_TYPE = 'MAPS_LIST_SET_INFOS_TYPE';
export const TOGGLE_LOADING = 'MAPS_LIST_TOGGLE_LOADING';
export const SET_LOADING = 'MAPS_LIST_SET_LOADING';
export const SET_SELECT_ITEM = 'MAPS_LIST_SET_SELECT_ITEM';
export const SET_IS_MAP = 'MAPS_LIST_SET_IS_MAP';
export const SET_IS_TRIANGLE = 'MAPS_LIST_SET_IS_TRIANGLE';
export const SET_IS_DRAW = 'MAPS_LIST_SET_IS_DRAW';
export const SET_SHOW_BUTTON_HISTORY = 'MAPS_LIST_SET_SHOW_BUTTON_HISTORY';
export const SET_CENTER_MAP = 'MAPS_LIST_SET_CENTER_MAP';
export const SET_SEARCH_DATE = 'MAPS_LIST_SET_SEARCH_DATE';

export const SET_ZOOM_MAP = 'MAPS_LIST_SET_ZOOM_MAP';


//Utils declaration


export const SEARCH_ALL = 'MAPS_LIST_GET_SEARCH_ALL';


// Select .* from ...  where id in [...] 
export const SEARCH_DEVICES = 'MAPS_LIST_GET_SEARCH_DEVICES';

// Select .* from ...  where group_id in [...] 
export const SEARCH_GROUPS = 'MAPS_LIST_GET_SEARCH_GROUPS';


// Select .* from ...  where id = ... 
export const SEARCH_DEVICE = 'MAPS_LIST_GET_SEARCH_DEVICE';

// Select .* from ...  where group_id = ... 
export const SEARCH_GROUP = 'MAPS_LIST_GET_SEARCH_GROUP';



// Select .* from ...   (get last event of  device)
export const SEARCH_ALL_LAST = 'MAPS_LIST_GET_SEARCH_ALL_LAST';

// Select .* from ...  where id = ...  (get last event of  device)
export const SEARCH_DEVICE_LAST = 'MAPS_LIST_GET_SEARCH_DEVICE_LAST';

// Select .* from ...  where group_id = ...  (get last event of all devices where group_id)
export const SEARCH_GROUP_LAST = 'MAPS_LIST_GET_SEARCH_GROUP_LAST';

//Interval date event
export const SEARCH_DATE= 'MAPS_LIST_GET_SEARCH_DATE';
    