import axios from "axios";

export const API_URL = process.env.VUE_APP_API_TRACKER_BASE_URL ?? 'http://localhost:8080';


const instance = axios.create({
    baseURL: API_URL
});

// Alter defaults after instance has been created

export default function ({method='GET',url="",data=[],isAuth= true}) {
    let token = localStorage.getItem("token") 
    if(isAuth && token != null){
        instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

   return  new Promise((resolve, reject) => {
                instance({
        method,
        url,
        data
    }).then((res)=>{
       
        return resolve(res); 
      
        // return res;

    }).catch((err)=>{

        if (err.response?.status == 401){
            localStorage.clear();
            location.replace("./login")
            // toast.success("Votre token n'est pas valide")
        }
        reject(err)
        // throw err

    })
      });
  
    

   
}
