<template>
    <div class="row" style="padding-bottom: 20px;">
        <div class="col-12 col-lg-8 col-xxl-9 d-flex">
            <div class="card flex-fill">
                <div class="card-header">
                    <div class="row">
                        <div class="col-12 col-lg-12 col-xxl-9 d-flex">
                            <div class="flex-fill">
                                <div class="card-header">
                                    <h5 class="card-title">Carte | Filtrer les données par date </h5>
                                </div>
                                <div class="d-flex" style="justify-content: space-between;align-items:center">
                                    <div class="d-flex" style="flex: 1;align-items: center;">
                                        <div ref="reportRange"
                                            class="overflow-hidden form-control d-flex justify-content-between align-items-center">
                                            <i class="far fa-calendar"></i>
                                            <span>{{ searchDate.startDate }} - {{ searchDate.endDate }}</span>
                                            <i class="fas fa-caret-down"></i>
                                        </div>



                                        <div class=" d-flex justify-content-center align-items-center"
                                            v-if="!isLoading">
                                            <div class="cursor-pointer mx-2" v-if="searchDate.startDate != null"
                                                @click="resetDate">
                                                <i class="fas fa-close" style="color: red;"></i>
                                            </div>

                                            <button class="btn mx-2  btn-primary d-flex align-items-center "
                                                v-if="searchDate.startDate != null" @click="filtrerData">
                                                <i class="fas fa-filter"></i>
                                                Filtrer
                                            </button>


                                        </div>
                                        <div v-if="isLoading" class="d-flex align-items-center px-2">
                                            <span class="loader  ml-3"></span>
                                            <div style="width: max-content;" class="ml-3"> Chargement ...</div>
                                        </div>

                                    </div>

                                    <div class="" v-if="!isLoading">
                                        <button v-if="showButtonHistory" class="btn mx-2  btn-primary"
                                            @click="getHistoryEvents">
                                            Historiques
                                        </button>
                                    </div>

                                    <div class="mx-2 d-flex" v-if="!isLoading">
                                        <label class="form-check d-block mr-2" for="tracer_id"
                                            v-if="!showButtonHistory">
                                            <input type="checkbox" id="tracer_id" class="form-check-input"
                                                :checked="isDraw" @change="setCheckBoxDraw('tracer')">
                                            <span class="form-check-label">Tracer</span>
                                        </label>

                                    </div>



                                </div>

                            </div>
                        </div>
                    </div>

                </div>

                <div style="height: 500px; width: 100%;" id="test"></div>


            </div>
        </div>
        <!-- information  -->
        <InformationContent />
    </div>

</template>
<style>
.pind {
    position: absolute !important;
    background-color: transparent !important;
    border: none;
    color: #fff;
}

.pindPoint {
    background-color: transparent !important;
    border: none !important;
    border-radius: 60px !important;
    width: 2px;
}

.pin {
    position: absolute;
    color: #fff;
    margin: -30px 0px 0px -10px;
    border-radius: 50% 50% 50% 0;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 28px;
    transform: rotate(-45deg);
}
</style>

<script>
import { defineComponent } from 'vue';
import { mapFields } from 'vuex-map-fields';
import 'jquery'; // Importez jQuery
import 'moment'; // Importez Moment.js
import 'daterangepicker'; // Importez Daterangepicker
import InformationContent from "../components/informations/InformationContent.vue";
import store from '@/store';
import * as types from '@/store/modules/map/list/mutation_types';
import { mapActions } from 'vuex';
import leaflet, { icon, marker } from 'leaflet';
export default defineComponent({

    components: {

        InformationContent,
    },

    computed: {
        ...mapFields('map/list', {
            items: 'items',
            center: 'centerMap',
            zoom: 'zoom',
            isDevice: 'isDevice',
            isDraw: 'isDraw',
            searchDate: 'searchDate',
            isTriangulate: "isTriangulate",
            isMap: "isMap",
            isLoading: 'isLoading',
            last_search_params: 'itemInfo',
            showButtonHistory: 'showButtonHistory'
        }),
        events() {
            let itemsSave = this.items;
            let trier = {}
            if (!this.isMap) {
                let i = 0;
                itemsSave.forEach(ite => {
                    if (ite != null) {
                        i += 1;
                        if (Object.keys(trier).indexOf(ite["imei"]) == -1) {
                            trier[ite["imei"]] = this.isDraw ? {
                                path: [],
                                geodesic: true,
                                strokeColor: ite['color'],
                                strokeOpacity: 1.0,
                                strokeWeight: 2,
                            } : {
                                paths: [],
                                strokeColor: ite['color'],
                                strokeOpacity: 0.8,
                                strokeWeight: 2,
                                fillColor: "#FF0000",
                                fillOpacity: 0.35,
                            }
                        }
                        trier[ite["imei"]][this.isDraw ? "path" : "paths"].push({ lat: parseFloat(ite['point_latitude']), lng: parseFloat(ite['point_longitude']) })
                    }
                });
                itemsSave = trier
            }

            return itemsSave;
        }


    },

    data() {
        return {
            apiKey: "AIzaSyAgMv6rGlmaFzNt6_Bq2-90rNmm8s6Uv9Q",
            map: null,
            markers: [],
            checked: false,

        };
    },

    watch: {
        items() {
            this.removeOldMarker();
            this.markerTracer();

        },
        isDraw() {
            this.removeOldMarker();
            if (this.isDraw) {
                this.polylineTracer();
            } else {
                this.markerTracer();
            }
        },
        center() {
            if (this.center) {
                if (!isNaN(this.center.lng) && !isNaN(this.center.lat)){

                    this.map.setView(this.center, this.zoom)
                }
            }

        }
    },


    mounted() {
        this.initDataRanger();
        this.initStreetMap();

    },
    unmounted() {
        this.resetMapData()
    },
    methods: {

        //Init Map (Leaflet,OpenStreetMap)
        initStreetMap() {
            this.map = leaflet.map("test")
            this.map.setView([9.5704107, 3.0290977], 7)

            leaflet.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
                maxZoom: 19,
                attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
            }).addTo(this.map)
        },

        // Add marker to map
        markerTracer() {
            const ins = []
            var icon = null


            this.items.forEach((item, i) => {
                if (item) {
                    icon = leaflet.divIcon({ className: " pind leaflet-div-icon ", html: `<div class="pin" style="background-color:${item.color}"><div style="transform:rotate(45deg)">${item.display_name}</div></div>` })
                    var mk = leaflet.marker([parseFloat(item.point_latitude), parseFloat(item.point_longitude)], { icon })
                    mk.on("click", (data) => {
                        this.setMapViewToMarker(item, data.latlng, 10)
                    })
                    mk.addTo(this.map)
                    ins.push(mk);
                }
            });
            this.markers = ins;

        },

        //Add Polyline tracer to map
        polylineTracer() {
            var polys = [];
            var icon = null;
            var mark = null;
            const markerDrawData = this.groupDataToPolylineTrace(this.items);

            const markersDataKey = Object.keys(markerDrawData);
            markersDataKey.forEach((imei, i) => {
                mark = markerDrawData[imei];

                var poly = leaflet.polyline(mark.data, mark.style).addTo(this.map)
                polys.push(poly);

                var color = mark.style.color;

                mark.data.forEach((mar, j) => {
                    icon = (j == 0 || j == mark.data.length - 1) ? leaflet.divIcon({ className: " pind leaflet-div-icon ", html: `<div class="pin" style="background-color:${color}"><div style="transform:rotate(45deg);font-size:10px">${j == 0 ? "Start" : "End"}</div></div>` }) : leaflet.divIcon({ className: "pindPoint leaflet-div-icon ", html: `<div class="" style="background-color:${color};min-width:100%;min-height:100%;border-radius:60px"></div>` })
                    poly = leaflet.marker(mar, { icon })
                    poly.on("click", (data) => {
                        this.setMapViewToMarker(null, data.latlng, 10)
                    })
                    poly.addTo(this.map)
                    polys.push(poly);
                })
            })
            this.markers = polys;
        },

        //Before traced Polyline filter
        groupDataToPolylineTrace(itemsSave) {

            let trier = {}
            itemsSave.forEach(ite => {
                if (ite != null) {
                    if (Object.keys(trier).indexOf(ite["imei"]) == -1) {
                        trier[ite["imei"]] = { data: [], style: {} }
                    }
                    trier[ite["imei"]]["data"].push([parseFloat(ite['point_latitude']), parseFloat(ite['point_longitude'])])
                    trier[ite["imei"]]["style"] = { color: ite.color };
                }
            });
            itemsSave = trier
            return itemsSave;
        },

        // Remove old marker and polyline
        removeOldMarker() {
            if (this.markers.length) {
                this.markers.forEach(marker => {
                    marker.remove();
                });
                this.markers = [];
            }
        },
        // Focus to Marker where on clicked
        setMapViewToMarker(selectItem, position, zoom = 10,) {
            this.centerMapToMarker({ position, zoom, selectItem })
        },


        ...mapActions({
            getMapData: "map/list/getMapData",
            resetMapData: "map/list/resetMapData",
            centerMapToMarker: "map/list/centerPosition"
        }),

        resetDate() {
            let par = this.last_search_params ?? ""
            let type = par.split("start_date=")[0]
            if (type[type.length - 1] == "&" || type[type.length - 1] == "?") {
                type = type.split("&")
                type.pop()
                type = type.join("&")

            }

            this.getMapData({ type, data: null, isParamsFilter: false })
            store.commit(`map/list/${types.SET_SEARCH_DATE}`, { startDate: null, endDate: null });



        },

        getSvgMarkerIcon(item) {

            const red = Math.floor(Math.random() * 256);
            const green = Math.floor(Math.random() * 256);
            const blue = Math.floor(Math.random() * 256);
            const rgbColor = "#" + ((1 << 24) + (red << 16) + (green << 8) + blue).toString(16).slice(1).toUpperCase();

            return {
                // path: 'M 0, 0 m -5, -5 L 5, -5 L 5, 5 L -5, 5 Z',
                path: 'M -5 6 L 0 -5 L 5 6 L 0 4 Z',
                fillColor: rgbColor,
                fillOpacity: 1,
                scale: 2,
            };
        },

        getHistoryEvents() {
            store.commit(`map/list/${types.SET_SHOW_BUTTON_HISTORY}`, false)
            if (this.last_search_params != null) {
                let type = this.last_search_params.replaceAll('lastevent=true', 'lastevent=false')
                if (!this.last_search_params.includes('lastevent')) {
                    type += '&lastevent=false'
                }
                this.getMapData({ type, data: null, isParamsFilter: false })
            } else {
                this.getMapData({ type: types.SEARCH_ALL, data: null })
            }
        },


        filtrerData() {
            this.getMapData({ type: types.SEARCH_DATE, data: { "lastSearch": this.last_search_params, "searchDate": this.searchDate } })
        },

        setCheckBoxDraw(changed) {
            store.commit(`map/list/${types.SET_IS_DRAW}`)
        },

        initDataRanger() {
            // Sélectionnez l'élément avec ref
            const reportRange = this.$refs.reportRange;

            // Initialisez Daterangepicker
            $(reportRange).daterangepicker({
                // Configuration Daterangepicker
                opens: "left",
                startDate: moment().subtract(29, "days"),
                endDate: moment(),
                ranges: {
                    "Aujourd'hui": [moment(), moment()],
                    "Hier": [moment().subtract(1, "days"), moment().subtract(1, "days")],
                    "7 derniers jours": [moment().subtract(6, "days"), moment()],
                    "30 derniers jours": [moment().subtract(29, "days"), moment()],
                    "Ce mois-ci": [moment().startOf("month"), moment().endOf("month")],
                    "Mois dernier": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
                }
            }, (start, end) => {
                store.commit(`map/list/${types.SET_SEARCH_DATE}`, { startDate: start.format("MMMM D, YYYY"), endDate: end.format("MMMM D, YYYY") })

                // Callback function
                // $(reportRange).find('span').html(start.format("MMMM D, YYYY") + " - " + end.format("MMMM D, YYYY"));
            });
        },
    }
});
</script>