<template>
    <li class="sidebar-item">
    <span class="sidebar-link">
        <div style="display:flex;justify-content:space-between">
            <div style="display:flex;align-items:center">
                <!-- Affichage de la couleur -->
                <div :style="{width: '12px', height:' 12px' , borderRadius: '0%', marginRight: '10px', backgroundColor: deviceItem.color,boxShadow:'1px 1px 1.8px #383838'}"></div>
                <span>{{ deviceItem.name }}</span>

            </div>
            <div style="display:flex;align-items:center">
                <!-- Affichage de l'état en ligne ou hors ligne -->
                <i class="fas fa-circle text-success fa-fw" v-if="deviceItem.online" style="font-size: 10px;"></i>
                <i class="fas fa-circle text-danger fa-fw" v-else style="font-size: 10px;"></i>
                <!-- Affichage de l'état de la batterie -->
                <i class="align-middle me-2 fas fa-fw fa-battery-empty" style="color: red;" v-if="deviceItem.lowbattery == 0"></i>
                <i class="align-middle me-2 fas fa-fw fa-battery-full" v-else></i>
                <!-- Affichage du dernier événement -->
                <span class="" v-if="deviceItem.last_event_time != null">{{ deviceItem.last_event_time }}</span>
                <span class="" v-else>N/A</span>
            </div>
        </div>
    </span>
</li>

</template>
<script>
// import { mapActions} from 'vuex';

export default {
    name: "DeviceItem",
    computed: {
       

    },

    props:{
        deviceItem:{
            type : Object,
            required:true,
        }
    },
    methods: {
       
    }
}
</script>