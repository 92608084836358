<template>
    <div class="col-12 col-lg-4 col-xxl-3 d-flex">
        <div class="card flex-fill w-100">
            <div class="card-header" style="display:flex;justify-content:space-between">
                <h5 class="card-title mb-0">Informations</h5>

            </div>
            <div class="card-body" style="overflow-y: auto; height: 300px" v-if="!isLoading">
                <template v-for="(item, i) in events" :key="i">

                    <div class="row py-3 device_info " :class="selectItem == i ? ' active' : ''" v-if="item != null"
                        @click="centerPosition(i, item.point_latitude, item.point_longitude)">
                        <div class="col-12 col-xg-12 d-flex">
                            <div class="" style="width: 20px; height: 20px;">
                                <i class="align-middle me-2  fa fa-fw fa-tablet" style="font-size: 15px;"></i>
                            </div>
                            <span>Device: <b> {{ item['name'] }} ( {{ item['display_name'] }} ) </b></span>
                        </div>
                        <div class="col-12 col-xg-12 d-flex">
                            <div class="" style="width: 20px; height: 20px;">
                                <i class="align-middle me-2 fa fa-fw fa-paperclip" style="font-size: 15px;"></i>
                            </div>
                            <span>Imei: <b> {{ item['imei'] }}</b></span>
                        </div>
                        <div class="col-12 col-xg-12 d-flex">
                            <div class="" style="width: 20px; height: 20px;">
                                <i class="align-middle me-2 fas fa-fw fa-calendar" style="font-size: 15px;"></i>
                            </div>
                            <span>Date: <b>{{ item['received_date'] }}</b></span> <br>
                        </div>

                        <div class="col-12 col-xg-12 d-flex ">
                            <div class="" style="width: 20px; height: 20px;">
                                <i class="align-middle me-2 fas fa-fw fa-map-marker" style="font-size: 15px;"></i>
                            </div>

                            <span>Position:
                                <b class="cursor-pointer" style="color: #007bff;">
                                    {{ address[i] }}
                                </b>
                            </span>

                        </div>



                    </div>
                    <!-- <hr v-if="item !=null" /> -->


                </template>

            </div>
            <div class="card-body " style="overflow-y: auto; height: 300px" v-else>
                <div
                    style="display: flex; flex-direction: column;justify-content: center;align-items: center;height: 80%;">
                    <span class="loader"> </span>
                    <b>Chargement en cours</b>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapActions } from "vuex"
import store from '@/store';
import * as types from '@/store/modules/map/list/mutation_types';



export default {
    components: {
    },
    data() {
        return {
            address: [],
        };

    },
    setup() {

    },
    name: "InformationContent",
    computed: {
        ...mapFields('map/list', {
            events: 'items',
            selectItem: "selectItem",
            itemInfo: "itemInfo",
            isLoading: 'isLoading'
        }),

    },

    watch: {
        events() {
            this.address = [];
            this.events.forEach((value, index) => {
                if (value != null) {
                    this.showAddress(index, value['point_latitude'], value['point_longitude']);

                }
            });

        },
        isLoading() {
            if (this.isLoading == false) {
                this.centerPosition(null, "9.5704107", "3.0290977", 6);
            }
        }
    },

    mounted() {


    },

    methods: {
        ...mapActions({
            centerMapToMarker: "map/list/centerPosition"
        }),

        centerPosition(selectItem, lat, long, zoom = 10) {
            var position = { lat: parseFloat(lat), lng: parseFloat(long) }
            this.centerMapToMarker({position, zoom,selectItem})
        },
        
        async getAddressFromCoordinates(latitude, longitude) {
            let geocoder = new google.maps.Geocoder();
            let location = new google.maps.LatLng(parseFloat(latitude), parseFloat(longitude));
            let address = await new Promise((resolve, reject) => {
                geocoder.geocode({ location }, (results, status) => {
                    if (status === 'OK') {
                        if (results[0]) {
                            resolve(results[0].formatted_address);
                        } else {
                            reject('Adresse non trouvée');
                        }
                    } else {
                        reject('Erreur lors de la récupération de l\'adresse');
                    }
                });
            });
            return address;
        },

        showAddress(i, latitude, longitude) {
            this.getAddressFromCoordinates(latitude, longitude).then((data) => {
                this.address[i] = data;
            }).catch(error => {
                this.address[i] = "Inconnue";
            });
        },

    }
}
</script>

<style>
#berdr {
    margin-top: 20px;
}

pre {
    margin-top: 20px;
}

.device_info:hover {

    background-color: #d3d3d369;
    cursor: pointer;
}

.device_info.active {

    background-color: #d3d3d38c;
    cursor: pointer;
}
</style>