import * as types from './mutation_types';

export default {
  [types.RESET](state) {
    Object.assign(state, {
      error: '',
      isLoading: false,
      items: [],
      search: null,
      isDevice: null,
      intemInfo: null,
      message: "",
      selectItem: null,
    });
  },

  [types.SET_ERROR](state, error) {
    Object.assign(state, { error });
  },
  [types.SET_LOADING](state, isLoading) {
    Object.assign(state, {
      isLoading
    });
  },

  [types.SET_ITEMS](state, items) {
    Object.assign(state, {
      error: '',
      items,
    });
  },
  [types.SET_IS_DEVICE](state, is_device) {
    Object.assign(state, {
      isDevice:is_device,
    });
  },

  [types.SET_ITEM_INFO](state, itemInfo) {
    Object.assign(state, { itemInfo });
  },
  [types.SET_MESSAGE](state, message) {
    Object.assign(state, {
      message
    });
  },

  [types.SET_SEARCH](state, search) {
    Object.assign(state, { search });
  },

  [types.SET_SELECT_ITEM](state, selectItem) {
    Object.assign(state, { selectItem });
  },

  [types.TOGGLE_LOADING](state) {
    Object.assign(state, { isLoading: !state.isLoading });
  },

  [types.SET_GROUPES_ITEMS](state, groupes) {
    Object.assign(state, {
      groupes
    });
  },
};
