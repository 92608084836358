import fetch from '@/utils/fetch';
import * as types from './mutation_types';
import { SET_LIST_REFRESH } from "@/store/modules/users/list/mutation_types";
import { useToast } from 'vue-toastification';

import store from '@/store';


export const deleteUser = ({
  commit
}, item, page = 'users',) => {
  commit(types.USER_DELETE_TOGGLE_LOADING);
  store.commit(`users/list/${SET_LIST_REFRESH}`, false)

  var id = page + '/' + item['id'];
  fetch({ method: 'DELETE', url: id })

    .then(({
      data
    }) => {
      commit(types.USER_DELETE_TOGGLE_LOADING);
      commit(types.USER_DELETE_SET_DELETED, item);
      store.commit(`users/list/${SET_LIST_REFRESH}`, true)
      const toast = useToast();
      toast.success('Utilisateur supprimé avec succès !'); // Afficher le toast

    })
    .catch((e) => {

      commit(types.USER_DELETE_TOGGLE_LOADING);
      commit(types.USER_DELETE_SET_ERROR, e.message);
      store.commit(`users/list/${SET_LIST_REFRESH}`, false)

    });
};


export const reset = ({ commit }) => {
  commit(types.USER_DELETE_RESET);
};


export default deleteUser;
