// import {
//     createApp
// } from 'vue'
// import App from './App.vue'
import * as VueRouter from "vue-router";
import Home from "@/views/pages/Home.vue";
import About from "@/views/pages/About.vue";
import Login from "@/views/pages/Login.vue";
import store from "@/store";

const router = VueRouter.createRouter({
  history: VueRouter.createWebHistory(),
  routes: [
    {
      path: "/",
      name: "Home",
      component: Home,
      
    },
    {
      path: "/about",
      name: "About",
      component: About,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
  ],
});

// const app = createApp(App)
// app.use(router).mount('#app')
// app.mount('app')

export default router;
