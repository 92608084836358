import fetch from "@/utils/fetch";
import * as types from "./mutation_types";
import showInMap from "@/store/modules/map/list/actions";
import store from "@/store";

const getDevices = ({ commit }, page = "devices") => {
  var params = "?lastevent=true";
  commit(types.TOGGLE_LOADING);
  store.commit(`map/list/MAPS_LIST_SET_LOADING`, true);

  fetch({ url: page + params })
    .then(({ data }) => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ITEMS, data["data"]);
      commit(types.SET_IS_DEVICE, data["isdevice"]);
      commit(types.SET_ITEM_INFO, data["info"]);
      commit(types.SET_SELECT_ITEM, null);
      commit(types.SET_SEARCH, "");

      commit(types.SET_MESSAGE, data["message"]);
      showInMap(data["data"], params);
      store.commit(`map/list/MAPS_LIST_SET_LOADING`, false);

      return data;
    })
    .catch((e) => {
      commit(types.TOGGLE_LOADING);
      commit(types.SET_ERROR, e.message);
      store.commit(`map/list/MAPS_LIST_SET_LOADING`, false);

    });
};


export const resetDevices = ({ commit }) => {
  commit(types.RESET);
};
// export const getDeviceEvents = ({
//   commit
// }, imei, page = 'devices', ) => {
//   commit(types.TOGGLE_LOADING);
//   let url = page + '/' + imei
//   fetch("GET", url, [], true)

//     .then(({
//       data
//     }) => {

//       // console.log(data['data']);
//       commit(types.TOGGLE_LOADING);
//       let events = data['data']['events'];
//       commit(types.SET_ITEMS, events[events.length - 1]);
//       // commit(types.SET_DATA, data['data']);
//       commit(types.SET_GROUP, data['data']);

//       commit(types.SET_META, data['message']);
//       return data;
//     })
//     .catch((e) => {
//       commit(types.TOGGLE_LOADING);
//       commit(types.SET_ERROR, e.message);
//     });
// };


export const getAllGroups = ({ commit }, page = "device-groups") => {

  fetch({ url: page })
    .then(({ data }) => {
      commit(types.SET_GROUPES_ITEMS, data["data"]);
      return data;
    })
    .catch((e) => {
    });
};

export default getDevices;
