import { createApp } from 'vue';
import App from './App.vue';
import router from "./router";
import store from "./store";
import "./assets/css/modern.css";
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/aura-light-green/theme.css'

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
// import $ from "jquery";
// import"./assets/js/settings.js"
// import "./assets/js/app.js"

const app = createApp(App);
app.use(router);
app.use(store);
app.use(PrimeVue);
app.use(Toast)
app.mount('#app')

