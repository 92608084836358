// import Vue from 'vue'
import Vuex from 'vuex'
import auth from './auth'
import devices from './modules/devices'
import map from './modules/map'
import messages from './modules/messages'
import users from './modules/users'

// Vue.use(Vuex)

export default new Vuex.Store({
    state: {
    },
    
    mutations: {


    },

    actions: {


    },

    modules: {
        auth,
        // group,
        devices,
        map,
        messages,
        users,
    }

})

// // export