import fetch from '@/utils/fetch';
import * as types from './mutation_types';
import store from '@/store';
import * as search from '@/store/modules/map/list/mutation_types';


const paramsFilter = (type, data,currentPage=1) => {

  let result = '?';

  if (type == search.SEARCH_GROUPS) {
    result += ''
  }
  if (type == search.SEARCH_DEVICES) {
    result += ''
  }
  if (type == search.SEARCH_GROUP || type == search.SEARCH_GROUP_LAST) {
    result += `group_id[eq]=${data}&`
  }

  if (type == search.SEARCH_DEVICE_LAST || type == search.SEARCH_DEVICE) {
    result += `sender_imei[eq]=${data}&`
  }


  if (type == search.SEARCH_DATE) {
    const { startDate = null, endDate = null } = data
    result += `start_date=${startDate}&end_date=${endDate}&`
  }
  result += `page=${currentPage}`
  // let t = new URLSearchParams(['id','2'])


  return result;

}

export const getMessages = ({
  commit
}, { type, data, isParamsFilter = true}, page = 'messages') => {

  var params = isParamsFilter ? paramsFilter(type, data) : type
  fetch({
    url: `${page}${params}`,
  })
    .then(({
      data
    }) => {
      commit(types.SET_ITEMS, data['data']);
      commit(types.SET_ITEM_INFO, params);
      commit(types.SET_MESSAGE, data['message']);
      commit(types.TOTAL_ROWS, data.meta.total);

      return data;
    })
    .catch((e) => {
      commit(types.SET_ERROR, e.message);
    });
};


export const getAllMessages = ({
  commit
}, page = 'messages') => {
  fetch({
    url: `${page}`,
  })
    .then(({
      data
    }) => {
      commit(types.SET_ITEMS, data['data']);
      commit(types.SET_ITEM_INFO, params);
      commit(types.SET_MESSAGE, data['message']);
      commit(types.TOTAL_ROWS, data.meta.total);
      return data;
    })
    .catch((e) => {
      commit(types.SET_ERROR, e.message);
    });
};

export default getMessages;