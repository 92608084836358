
<template>


        <main class="main h-100 w-100"  >
            <div class="container h-100">
                <div class="row h-100">
                    <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
                        <div class="d-table-cell align-middle">

                            <div class="text-center mt-4">
                                <h1 class="h2">Bienvenue sur GES Tracker - ALIBORA</h1>
                                <p class="lead">
                                    Connectez-vous à votre compte pour continuer
                                </p>
                            </div>

                            <div class="card">
                                <div class="card-body">

                                    <div class="m-sm-4">
                                        <div class="text-center">
                                            <img src="@/assets/img/avatars/avatar.jpg" alt="A"
                                                class="img-fluid rounded-circle" width="132" height="132" />
                                        </div>
                                        <div class="alert alert-danger alert-dismissible mt-4" role="alert" v-if="error">
                                            <div class="alert-message">
                                                <strong>Désolé!, </strong> {{ message }}
                                            </div>

                                            <button type="button" class="btn-close btn-close-white" data-bs-dismiss="alert"
                                                aria-label="Close">
                                            </button>
                                        </div>

                                        <div class="mb-3">
                                            <label>Nom d'utilisateur</label>
                                            <input class="form-control form-control-lg" type="text" name="email"
                                                placeholder="Enter your email" v-model="email" />
                                        </div>
                                        <div class="mb-3">
                                            <label>Mot de passe</label>
                                            <input class="form-control form-control-lg" type="password" name="password"
                                                placeholder="Enter your password" v-model="password" />

                                        </div>

                                        <div class="text-center mt-3">
                                            <div class="btn btn-lg btn-primary" @click="handleSubmit()" v-if="!loading">Se  connecter</div>
                                            <div class="btn btn-lg btn-dark" v-else>En cours ...</div>
                                            <!-- <button type="submit" class="btn btn-lg btn-primary">Sign in</button> -->
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </main>


</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'LoginPage',
    data() {
        return {
            email: "",
            password: "",
            loading: false,
            error: false,
            message: "Email ou Mot de passe incorrect",
        }
    },
    computed: {

    },
    methods: {
        ...mapActions({
            submitForm: 'auth/login'
        }),
        handleSubmit() {
            var elt = this;

            if (this.email != "" && this.password != "") {
                let credentials = { email: this.email, password: this.password }
                this.loading = true;
                this.submitForm(credentials).then(() => {
                    this.loading = false;
                    this.error = false;
                    elt.$router.replace({ name: 'Home' });

                }).catch((e) => {

                    this.loading = false;
                    this.error = true;
                    this.message = 'Email ou Mot de passe incorrect'
                });
            } else {

                this.error = true;
                this.loading = false;
                this.message = 'Les champs sont obligatoires'
            }
        },
        handleKeyPress(event) {
            if (event.keyCode === 13) {
                this.handleSubmit();
            }
        }
    },
    mounted() {
        // Écouter l'événement keydown sur les champs de formulaire
        document.addEventListener('keydown', this.handleKeyPress);
    },
    beforeUnmount() {
        // Supprimer l'écouteur d'événement lors de la destruction du composant
        document.removeEventListener('keydown', this.handleKeyPress);
    }
}
</script>

<style></style>