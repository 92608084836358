import { getField, updateField } from 'vuex-map-fields';
import * as actions from './actions';
import mutations from './mutations';

export default {
  namespaced: true,
  state: {
    error: '',
    isLoading: false,
    items: [],
    messages: '',
    itemInfo: null,
    currentPage: 1,
    perPage: 10,
    totalRows: 0 

  },
  actions,
  getters: {
    getField,
  },
  mutations: {
    updateField,
    ...mutations,
  }
};
