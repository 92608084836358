<template>
    <div class="header">


        <h1 class="header-title" v-if="itemInfo != null && !isLoading">
            <template v-if="isDevice">


                <template v-if="infosPro != null">
                    <div style="display:flex;justify-content:space-between">
                        <div style="display:flex;align-items:center">
                            <div  style="margin-right: 5px;">Device: </div> <b></b>
                            <div :style="{ width: '18px', height: ' 18px', borderRadius: '0%', marginRight: '10px', backgroundColor: infosPro['color'], boxShadow: '1px 1px 1.8px #383838' }">
                            </div>
                            <span>{{ infosPro['name'] }} ({{ infosPro['imei'] }})</span>

                        </div>

                    </div>
                </template>
            </template>

            <template v-else>
                <!-- {{ JSON.stringify() }} -->
                <template v-if="infosPro == null">
                    Groupe: {{ itemInfo['name'] }} ({{ items.length }} devices )
                </template>

                <template v-else>
                    Groupe: {{ infosPro['name'] }}
                </template>
            </template>

        </h1>
        <h1 class="header-title" v-if="isLoading">
            ...
        </h1>
        <!-- <p class="header-subtitle">You have 24 new messages and 5 new notifications.</p> -->

    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
export default {


    computed: {
        ...mapFields('devices/list', {
            items: "items",
            itemInfo: "itemInfo",
        }),

        ...mapFields('map/list', {
            // items: "items",
            isDevice: "isDevice",
            infosPro: "infosType",
            isLoading: "isLoading"
        })
    },

    mounted() {
    },

    methods: {

    }
}

</script>